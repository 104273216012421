.page_container {
  padding: 20px 40px;
  text-align: left;
  color: #313131;
}

.page_title {
  font-size: 50px;
  font-family: "Libre Baskerville", serif;
  font-weight: 600;
  line-height: 75px;
  text-align: center;
  margin-bottom: 40px;
  text-transform: capitalize;
}

@media screen and (max-width: 650px) {
  .page_container {
    padding: 20px 20px;
  }

  .page_title {
    font-size: 40px;
    line-height: 75px;
    margin-bottom: 30px;
  }
}
