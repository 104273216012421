.bot-product-list {
  justify-content: flex-start;
  scroll-behavior: smooth;
  min-height: 240px;
  cursor: grab;
}

.bot-product-item {
  min-height: 260px;
  width: auto !important;
  display: flex !important;
  flex-direction: column;
  background: var(--white);
  color: var(--secondary-color);
  border-radius: 8px;
  margin: 24px 0;
  margin-right: 24px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  overflow: hidden;
  box-shadow: 0 3px 4px 0px #68548dde;
  cursor: pointer;
}
.bot-product-item:hover {
  filter: brightness(0.94);
}

.bot-product-player {
  padding: 16px;
  padding-bottom: 0px;
  border-radius: 0;
  overflow: hidden;
  background: white;
}

.bot-product-player.purple-bg {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: var(--main-color);
  border-bottom: 4px solid #b8aad2;
}

.bot-product-player:hover > .bot-product-title {
  /* display: none; */
  content: "View Product";
}

.bot-product-detail-container {
  border: 4px solid var(--main-color);
  border-radius: 12px;
  overflow: hidden;
}

.bot-product-title {
  font-size: 12px;
  padding-top: 8px;
  color: var(--main-color);
  font-weight: 800;
}

.text-white {
  color: var(--white);
}

.bot-product-sub-title {
  font-size: 12px;
  color: var(--main-color);
  font-weight: 400;
  padding-bottom: 12px;
}

.bot-product-button {
  padding: 8px;
  font-size: 12px;
  background: var(--main-color);
  color: var(--white);
  margin-top: auto;
}

.bot-product-img {
  object-fit: cover;
  height: 120px;
}

.slick-prev {
  left: -60px;
}

.bot-product-list > .slick-prev:before,
.bot-product-list > .slick-next:before,
.bot-video-list > .slick-prev:before,
.bot-video-list > .slick-next:before {
  color: var(--main-color);
  font-size: 40px;
  z-index: 999;
}
